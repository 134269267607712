import i18n from "../../../i18n";

export const fieldsColumnsMap = [
  { name: i18n.advanceReportsIDHSDeal, field: 'dealId' },
  { name: i18n.advanceReportsAdvertiser, field: 'advertiser' },
  { name: i18n.advanceReportsClient, field: 'client' },
  { name: i18n.advanceReportsCampaignId, field: 'campaignId' },
  { name: i18n.advanceReportsCampaign, field: 'campaign' },
  { name: i18n.advanceReportsLineItemId, field: 'lineitemId' },
  { name: i18n.advanceReportsLineItem, field: 'lineItem' },
  { name: i18n.advanceReportsCreativeId, field: 'creativeId' },
  { name: i18n.advanceReportsCreative, field: 'creative' },
  { name: i18n.advanceReportsInventoryType, field: 'inventoryType' },
  { name: i18n.advanceReportsFormat, field: 'format' },
  { name: i18n.advanceReportsOfferType, field: 'offerType' },
  { name: i18n.advanceReportsCountry, field: 'country' },
  { name: i18n.advanceReportsCountryApp, field: 'countryApp' },
  { name: i18n.advanceReportsCountryYoutube, field: 'countryYoutube' },
  { name: i18n.advanceReportsAppName, field: 'placement' },
  { name: i18n.advanceReportsYoutubeChannel, field: 'placement' },
  { name: i18n.advanceReportsDevices, field: 'devices' },
  { name: i18n.advanceReportsDate, field: 'date' },
  { name: i18n.advanceReportsMonth, field: 'monthName' },
  { name: i18n.advanceReportsStartDate, field: 'startDate' },
  { name: i18n.advanceReportsEndDate, field: 'endDate' },
  { name: i18n.advanceReportsGoal, field: 'goal' },
  { name: i18n.advanceReportsImpressions, field: 'impressions' },
  { name: i18n.advanceReportsViews, field: 'completeViewsVideo' },
  { name: i18n.advanceReportsFirstQuartile, field: 'firstQuartile' },
  { name: i18n.advanceReportsMidQuartile, field: 'midQuartile' },
  { name: i18n.advanceReportsThirdQuartile, field: 'thirdQuartile' },
  { name: i18n.advanceReportsClicks, field: 'clicks' },
  { name: i18n.advanceReportsEngagements, field: 'engagements' },
  { name: i18n.advanceReportsVTR, field: 'vtr' },
  { name: i18n.advanceReportsCTR, field: 'ctr' },
  { name: i18n.advanceReportsER, field: 'er' },
  { name: i18n.advanceReportsCurrency, field: 'currencyCode' },
  { name: i18n.advanceReportsInversionBudgetCurrency, field: 'inversionBudget' },
  { name: i18n.advanceReportsInversionBudgetUSD, field: 'inversionBudgetUsd' },
  { name: i18n.advanceReportsSpentCurrencyDeal, field: 'spent' },
  { name: i18n.advanceReportsSpentUSD, field: 'spentUsd' },
  { name: i18n.advanceReportsCpmCurrency, field: 'cpmCurrency' },
  { name: i18n.advanceReportsCpm, field: 'cpm' },
  { name: i18n.advanceReportsCpvCurrency, field: 'cpvCurrency' },
  { name: i18n.advanceReportsCpv, field: 'cpv' }
];