import i18n from "../../../i18n"

export const initialOrderPerformanceReport = [
  i18n.advanceReportsIDHSDeal,
  i18n.advanceReportsAdvertiser,
  i18n.advanceReportsClient,
  i18n.advanceReportsCampaignId,
  i18n.advanceReportsCampaign,
  i18n.advanceReportsLineItemId,
  i18n.advanceReportsLineItem,
  i18n.advanceReportsCreativeId,
  i18n.advanceReportsCreative,
  i18n.advanceReportsInventoryType,
  i18n.advanceReportsFormat,
  i18n.advanceReportsOfferType,
  i18n.advanceReportsCountry,
  i18n.advanceReportsCountryApp,
  i18n.advanceReportsCountryYoutube,
  i18n.advanceReportsAppName,
  i18n.advanceReportsYoutubeChannel,
  i18n.advanceReportsDevices,
  i18n.advanceReportsDate,
  i18n.advanceReportsMonth,
  i18n.advanceReportsStartDate,
  i18n.advanceReportsEndDate,
  i18n.advanceReportsGoal,
  i18n.advanceReportsImpressions,
  i18n.advanceReportsViews,
  i18n.advanceReportsFirstQuartile,
  i18n.advanceReportsMidQuartile,
  i18n.advanceReportsThirdQuartile,
  i18n.advanceReportsClicks,
  i18n.advanceReportsEngagements,
  i18n.advanceReportsVTR,
  i18n.advanceReportsCTR,
  i18n.advanceReportsER,
  i18n.advanceReportsCurrency,
  i18n.advanceReportsInversionBudgetCurrency,
  i18n.advanceReportsInversionBudgetUSD,
  i18n.advanceReportsSpentCurrencyDeal,
  i18n.advanceReportsSpentUSD,
  i18n.advanceReportsCpmCurrency,
  i18n.advanceReportsCpm,
  i18n.advanceReportsCpvCurrency,
  i18n.advanceReportsCpv
]